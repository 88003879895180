import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import ReactGA from "react-ga";
import { Container } from "react-bootstrap";
import { useEffect } from "react";
import BannerAd from "./components/ads";

import Home from "./pages/home";
import Category from "./pages/category";
import Article from "./pages/article";
import Author from "./pages/author";
import SearchBar from "./pages/search";
import StaticPages from "./pages/static-pages";
import Highlights from "./pages/highlights";
import SearchAuthor from "./pages/searchAuthors"
import NotFoundPage from "./pages/NotFound";
import { Helmet } from "react-helmet-async";
import "./App.css";

ReactGA.initialize("UA-184469006-1");
ReactGA.pageview(window.location.pathname + window.location.search);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const links = [
  "https://thepolitic.org/category/:id=2317", "https://thepolitic.org/category/:id=3", 
  "https://thepolitic.org/category/:id=7", "https://thepolitic.org/category/:id=2853", 
  "https://thepolitic.org/page/:id=columns", "https://thepolitic.org/category/:id=4284", 
  "https://thepolitic.org/category/:id=957", "https://thepolitic.org/category/:id=4291", 
  "https://thepolitic.org/page/id=documentary", "https://thepolitic.org/page/id=our-team",
  "https://thepolitic.org/page/id=our-history"
]
const App = () => {
  return (
    <>
      {/* invisible links for seo */}
      {links.map((link, i) => <a key={i} href={link} style={{display: "none"}}> </a>)}

      <Helmet prioritizeSeoTags>
        <meta
          name="description"
          content="Yale's Political Publication Since 1947"
        />
      </Helmet>

      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Container fluid className="main p-0" style={{ minHeight: "45vh" }}>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/category/:id" element={<Category />} />
            <Route path="/article/:article" element={<Article />} />
            <Route path="/page/:id" element={<StaticPages />} />
            <Route path="/author/:id" element={<Author />} />
            <Route path="/highlights" element={<Highlights />} />
            <Route path="/search" element={<SearchBar />} />
            <Route path="/searcha" element={<SearchAuthor />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Container>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;
