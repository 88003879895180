import logo from "../assets/logos/white-logo.png";
import insta from "../assets/icons/insta.png";
import fb from "../assets/icons/fb.png";
import twitter from "../assets/icons/twitter.png";
import { Container, Row, Col, Image, ListGroup, Nav } from 'react-bootstrap';

const Footer = () => {
  return (
    <Container fluid className="footer bg-black text-white p-4">
      <Row className="justify-content-between">
        <Col md={3}>
            <Image src={logo} alt="logo" width="150px" fluid />
        </Col>
        <Col md={4} className="mb-4 mb-md-0">
          <h5 className="border-bottom pb-2 font-weight-bold">Read More</h5>
          <Row>
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                  <Nav.Link href="/category/:id=2317" className="text-white p-0">Local</Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                  <Nav.Link href="/category/:id=3" className="text-white p-0">National</Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                  <Nav.Link href="/category/:id=7" className="text-white p-0">World</Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                  <Nav.Link href="/category/:id=2853" className="text-white p-0">Culture</Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                  <Nav.Link href="/category/:id=949" className="text-white p-0">Opinion</Nav.Link>
                </ListGroup.Item>
              </ListGroup>
          </Col>
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                  <Nav.Link href="/page/:id=columns" className="text-white p-0">Columnists</Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                  <Nav.Link href="/page/id=documentary" className="text-white p-0">Documentary</Nav.Link>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Col>
        <Col md={3}>
          <h5 className="border-bottom pb-2 font-weight-bold">Find Us</h5>
          <ListGroup variant="flush">
            <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
              <Nav.Link href="https://issuu.com/theyalepolitic" target="_blank" rel="noopener noreferrer" className="text-white p-0">Mag</Nav.Link>
            </ListGroup.Item>
            <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
              <Nav.Link href="/page/id=our-team" className="text-white p-0">Masthead</Nav.Link>
            </ListGroup.Item>
            <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
              <Nav.Link href="/page/id=our-history" className="text-white p-0">Our History</Nav.Link>
            </ListGroup.Item>
            <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
              <Nav.Link href="mailto:thepolitic@yale.edu" className="text-white p-0">Contact Us</Nav.Link>
            </ListGroup.Item>
          </ListGroup>
          <div className="d-flex mt-3">
            <Nav.Link href="https://www.facebook.com/theyalepolitic" target="_blank" rel="noopener noreferrer" className="p-0 mr-2">
              <Image src={fb} alt="Facebook" width="40px" />
            </Nav.Link>
            <Nav.Link href="https://www.instagram.com/theyalepolitic/" target="_blank" rel="noopener noreferrer" className="p-0 mr-2">
              <Image src={insta} alt="Instagram" width="40px" />
            </Nav.Link>
            <Nav.Link href="https://twitter.com/yalepolitic" target="_blank" rel="noopener noreferrer" className="p-0">
              <Image src={twitter} alt="Twitter" width="40px" />
            </Nav.Link>
          </div>
        </Col>
      </Row>
    </Container>
  )
};

export default Footer;
