import React from "react";
import { useNavigate } from "react-router-dom";

const AuthorCard = ({ title, id }) => {
  const navigate = useNavigate();

  const goToAuthor = () => {
    navigate("/author/:id=" + id);
  };

  return (
    <div
      style={{
        paddingLeft: "20px",
        display: "flex",
        marginBottom: "3%",
      }}
    >
      <div style={{ paddingRight: "2%", width: "100%" }}>
        <hr />
        <div className="card-title"
          style={{
            fontFamily: "Roboto Slab",
            fontSize: "17px",
          }}
          onClick={goToAuthor}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>

    </div>
  );
};

export default AuthorCard;
