import local_alt from "../assets/alt-images/local-article.png";
import national_alt from "../assets/alt-images/national.png";
import culture_alt from "../assets/alt-images/culture.png";
import world_alt from "../assets/alt-images/world.png";
import experts_alt from "../assets/alt-images/experts.png";
import voices_alt from "../assets/alt-images/voices.png";
import opinion_alt from "../assets/alt-images/opinion.png";
import sophist_alt from "../assets/alt-images/sophist.png";


// Category parsing and alt images
const Categories = [
  { "id": 2317, "name": "Local", "alt": local_alt },
  { "id": 3, "name": "National", "alt": national_alt },
  { "id": 7, "name": "World", "alt": world_alt },
  { "id": 949, "name": "Opinion", "alt": opinion_alt },
  { "id": 2853, "name": "Culture", "alt": culture_alt },
  { "id": 957, "name": "Interviews", "alt": experts_alt },
  { "id": 4291, "name": "Voices Of", "alt": voices_alt },
  { "id": 4284, "name": "The Sophist", "alt": sophist_alt }
];
export const getCategory = (id) => {
  id = typeof id === "string" ? parseInt(id) : id;
  return Categories.find((category) => category.id === id);
}

export const categoryIdToName = (id) => {
  return getCategory(id).name;
}
export const categoryIdToAltImage = (id) => {
  return getCategory(id)?.alt || world_alt;
}

