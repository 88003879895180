import React, { useState, useEffect } from "react";
import insta from "../assets/icons/insta.png";
import fb from "../assets/icons/fb.png";
import twitter from "../assets/icons/twitter.png";
import { Nav, Navbar, NavDropdown, Form, FormControl, Button, Modal, Container, Image } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { parseUTC } from "../utils/helper";


// Define the links for the navigation bar
const NAV_LINKS = [
  { id: 2317, label: 'Local' },
  { id: 3, label: 'National' },
  { id: 7, label: 'World' },
  { id: 2853, label: 'Culture' },
  { id: 949, label: 'Opinion' },
  { label: 'Interviews', dropdown: [ 
    { id: 957, label: 'All Interviews' },
    { id: 4619, label: 'Understanding the Situation in Ukraine' },
  ]},
  { label: 'Multimedia', dropdown: [
    { label: 'Documentary', href: '/page/id=documentary' },
  ]},
  { label: 'About', dropdown: [
    { label: 'Masthead', href: "/page/id=our-team" },
    { label: 'Publications', href: "https://issuu.com/theyalepolitic", target: "_blank", rel: "noopener noreferrer" },
    { label: 'Our History', href: "/page/id=our-history" },
    { label: 'Contact Us', href: "mailto:thepolitic@yale.edu" },
  ]}
];


// Header component
const Header = () => {
  // Navbar changes to a dropdown (burger) menu when the screen is small
  // and has a search bar to search articles. The logo above the navbar
  // does not follow the scroll of the page, but the navbar does.

  const [isSticky, setSticky] = useState(false);
  const [visible, setVisible] = useState(false);  // for search bar
  const [isMainpage, setMainpage] = useState(false);
  const location = useLocation();

  // Add sticky class to navbar when user scrolls past 125px
  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 105);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Check if on home page or other main page
  useEffect(() => {
    setMainpage(location.pathname === "/" || location.pathname.startsWith("/category/") || location.pathname.startsWith("/page/"));
  }, [location]);
  
  return (
    <>
      <Container fluid className="d-flex flex-column align-items-center noto-sans" style={{ minHeight: isMainpage ? "175px" : "75px", fontSize: "14px" }}>
        { // Only show logo and date on home page
          isMainpage && (
            <div className="d-flex flex-column align-items-center text-center py-3 w-100 border-bottom border-darkgrey">
              <h1 className="fs-1 fst-italic mb-1">
                <a className="text-decoration-none text-black" href="/">
                  The Politic
                </a>
              </h1>
              <span className="text-black roboto" style={{fontWeight: "bold"}}>{parseUTC()}</span>
            </div>
          )
        }

        <Navbar bg="white" expand="lg" fixed={(isSticky || !isMainpage) ? "top" : ""} className="d-flex flex-row gap-1 align-items-center">
          <Nav.Link href="/" className="text-nowrap fs-3 fst-italic" style={(isSticky || !isMainpage) ? {width: "auto", height: "auto", opacity: 1, transition: "all 0.2s ease-in"} : {width: 0, height: 0, opacity: 0}}>
            The Politic
          </Nav.Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav activeKey={window.location.pathname} className="d-flex flex-column flex-md-row justify-content-between align-items-center">
              <div></div>
              <div className="d-flex flex-column flex-md-row gap-2 justify-content-center align-items-center">
                {NAV_LINKS.map((link) => {
                  if (link.dropdown) {
                    return (
                      <NavDropdown key={link.label} title={link.label} id={`dropdown-${link.label}`}>
                        {link.dropdown.map((dropdownLink) => (
                          <NavDropdown.Item key={dropdownLink.label} href={dropdownLink.href || `/category/:id=${dropdownLink.id}`} target={dropdownLink.target} rel={dropdownLink.rel}>
                            {dropdownLink.label}
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    );
                  } else {
                    return (
                      <Nav.Link key={link.id} href={`/category/:id=${link.id}`}>
                        {link.label}
                      </Nav.Link>
                    );
                  }
                })}
                <Nav.Link className="fa fa-search cursor-pointer" onClick={() => setVisible(true)} />
              </div>
              <div className="d-flex gap-2 p-2">
                <Nav.Link href="https://www.facebook.com/theyalepolitic" target="_blank" rel="noopener noreferrer" className="p-0 mr-2">
                  <Image src={fb} alt="Facebook" height="20px" />
                </Nav.Link>
                <Nav.Link href="https://www.instagram.com/theyalepolitic/" target="_blank" rel="noopener noreferrer" className="p-0 mr-2">
                  <Image src={insta} alt="Instagram" height="20px" />
                </Nav.Link>
                <Nav.Link href="https://twitter.com/yalepolitic" target="_blank" rel="noopener noreferrer" className="p-0">
                  <Image src={twitter} alt="Twitter" height="20px" />
                </Nav.Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
      <Modal show={visible} onHide={() => setVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form inline action="/search">
            <FormControl type="text" placeholder="Search articles" className="mr-sm-2" id="header-search" name="s" />
            <Button type="submit" className="mt-2">Search</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;
