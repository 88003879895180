import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';

const NotFoundPage = () => (
    <Container fluid className="text-center p-5 my-5">
        <h3>404 - Page Not Found!</h3>
        <br/>
        <Link to="/" className="btn btn-primary">Go Home</Link>
    </Container>
);

export default NotFoundPage;