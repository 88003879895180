import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Card } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import AdSense from "react-adsense";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { fetchArticles } from "../utils/api";
import { parseUTC } from "../utils/helper";

const itemsPerPage = 10;

const ArticlesList = ({ fetchParams }) => {
  let [articles, setArticles] = useState(null);
  let [page, setPage] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();

  const goToArticle = (slug) => {
    navigate("/article/" + slug);
  };

  useEffect(() => {
    let newPage = searchParams.get("page");
    if (newPage) {
      newPage = parseInt(newPage);
      setPage(newPage);
    } else setPage(1);
  }, [searchParams]);

  // Get articles from category id and page number
  useEffect(() => {
    const getArticles = async () => {
      if (page == null) return;
      try {
        let offset = (page - 1) * itemsPerPage;
        let response = await fetchArticles({_embed: true, per_page: itemsPerPage, offset: offset, ...fetchParams});
        setArticles(response);
      } catch (error) {
        console.log(error);
      }
    };
    getArticles();
  }, [page, fetchParams]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
    searchParams.set("page", pageNumber);
    window.scrollTo(0, 0);
  }

  return (
    <Container fluid>
      {articles == null ? (
        <SkeletonTheme color="#E5E5E5" highlightColor="#F2F2F2">
          <div style={{ margin: "50px 0" }}>
            <Skeleton height={250} count={3} />
          </div>
        </SkeletonTheme>
      ) : (
        <>
          {articles.map((article, index) => (
            <Card key={article.id || index} className="mb-4">
              <hr/>
              <Row className="g-0">
                <Col md={4}>
                  <Card.Img src={article.imgSmall} className="object-fit-cover w-100 cursor-pointer" style={{height: "250px"}} onClick={() => goToArticle(article.slug)} />
                </Col>
                <Col md={7}>
                  <Card.Body>
                    <Card.Title className="roboto" dangerouslySetInnerHTML={{ __html: article.title.rendered }} onClick={() => goToArticle(article.slug)} />
                    <Card.Subtitle className="mb-2 text-muted">{article.author.name} - {parseUTC(article.date)}</Card.Subtitle>
                    <Card.Text className="inter" dangerouslySetInnerHTML={{ __html: article.excerpt.rendered }} />
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          ))}
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            initialPage={page - 1}
            pageCount={page + 2}
            onPageChange={(data) => handlePageChange(data.selected + 1)}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </>
      )}
    </Container>
  )
};

export default ArticlesList;
