import axios from "axios";
import { categoryIdToAltImage } from "./api-helper";

const netlifyFunctionsPath = "/.netlify/functions";
//const netlifyFunctionsPath = "../../static/js";

const serverQuery = async (path) => {
  // a simple wrapper for making fetch GET requests
  // no need to supply the whole URL, only the relative path
  return axios.post(`${netlifyFunctionsPath}/serverQuery`, { path: path });
};

const backendURL = "https://thepoliticbackend.org/wp-json/wp/v2";  // this is the path to the backend

const queryBackend = async (relativePath) => {
  return serverQuery(`${backendURL}/${relativePath}`);
};

export const fetchResponseFromAPI = async (endpoint, params = {}) => {
  return queryBackend(endpoint + "?" + new URLSearchParams(params));
};

export const fetchFromAPI = async (endpoint, params = {}) => {
  return fetchResponseFromAPI(endpoint, params).then((response) => {
    return response.data;
  });
};


// Custom functions

const processArticle = (article) => {
  article.category = article.categories[0];
  article.author = article._embedded["author"]?.[0];

  const media = article._embedded["wp:featuredmedia"]?.[0];
  article.img = media?.source_url || categoryIdToAltImage(article.category);
  article.caption = media?.caption?.rendered;

  const sizes = media?.media_details?.sizes;
  article.imgPost = (sizes && sizes["post-thumbnail"]?.source_url) || article.img;
  article.imgMedium = sizes?.medium?.source_url || article.img;
  article.imgSmall = sizes?.medium_large?.source_url || article.img;
  article.imgThumbnail = sizes?.thumbnail?.source_url || article.img;

  return article;
}

export const fetchArticles = async (params = {}) => {
  return fetchFromAPI("posts", params).then((articles) => {
    return articles.map(processArticle);
  });
}

export const fetchArticle = async (slug, embed=true) => {
  return fetchArticles({ slug: slug, _embed: embed }).then((articles) => articles[0]);
}

export const fetchUser = async (id) => {
  return fetchFromAPI("users/" + id);
}
