import React from "react";
import { Button, ButtonGroup, Container, Row, Col } from 'react-bootstrap';

const Mailchimp = () => {
  return (
    <Container fluid>
      <Row className="mx-5 my-4">
        <Col>
          <ButtonGroup className="d-flex flex-md-row flex-column justify-content-between align-items-center merriweather">
            <Button 
              variant="light"
              className="text-dark mx-3 mb-1 w-100 rounded-pill border-2 border-black"
              href="http://eepurl.com/dILf39"
            >
              Sign Up for Our Email List
            </Button>
            <Button 
              variant="dark"
              className="text-white mx-3 mb-1 w-100 rounded-pill border-2 border-white" 
              href="https://www.paypal.com/donate/?hosted_button_id=R4R2ZECPZSHBW"
            >
              Donate to Us!
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default Mailchimp;
