import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { parseUTC } from "../../utils/helper";
import { categoryIdToAltImage } from "../../utils/api-helper";

const ImgContainer = styled.div`
  padding-top: 10px;
  position: relative;
  width: 30%;
  height: 100px;
`;

const CategoryCard = ({ title, text, image, slug, date, category }) => {
  const navigate = useNavigate();
  const [img, setImg] = useState(image);

  useEffect(() => {
    if (img === null || img === undefined) {
      setImg(categoryIdToAltImage(category))
    }
  }, [img, category])

  const goToArticle = () => {
    navigate("/article/" + slug);
  };

  return (
    <div
      style={{
        paddingLeft: "20px",
        display: "flex",
        marginBottom: "3%",
      }}
    >
      <div style={{ paddingRight: "2%", width: "100%" }}>
        <hr />
        <div className="card-title"
          style={{
            fontFamily: "Roboto Slab",
            fontSize: "17px",
          }}
          onClick={goToArticle}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div className="card-subcontent">{parseUTC(date)}</div>
        <div
          style={{
            fontFamily: "Inter",
            fontSize: "12px",
          }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
      <ImgContainer>
        <img
          alt="coverphoto"
          src={img}
          style={{ objectFit: "cover", width: "100%", height: "100%" }}
        />
      </ImgContainer>
    </div>
  );
};

export default CategoryCard;
