import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import AdSense from "react-adsense";
import ArticlesList from "../components/articles-list";

const Highlights = () => {
  return (
    <Container fluid>
      <Helmet>
        <title>The Yale Politic - Highlights</title>
      </Helmet>

    <Container fluid>
        <Container fluid className="my-5">
        <Col className="text-center mb-5">
            <h1 className="fs-1">Highlights</h1>
        </Col>
        </Container>
        <Container fluid className="my-5">
        <ArticlesList />
        </Container>
    </Container>

      <Row className="my-5 adsense-container">
        <Col>
          <AdSense.Google client="ca-pub-6983293802174566" slot="2769088860" />
        </Col>
      </Row>
    </Container>
  )
};

export default Highlights;
