import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Card } from "react-bootstrap";
import AdSense from "react-adsense";
import { categoryIdToName } from "../utils/api-helper";
import ArticlesList from "../components/articles-list";

const Category = () => {
  let [categoryId, setCategoryId] = useState(null);
  let [categoryName, setCategoryName] = useState(null);
  let [loading, setLoading] = useState(true);
  let params = useParams();

  // Get category name from category id param
  useEffect(() => {
    const getCategory = async () => {
      setLoading(true);
      let id = params.id.slice(4);
      try {
        setCategoryName(categoryIdToName(id));
      } catch (error) {
        console.log(error);
      }
      
      if (id === "957") id = "957,4291";
      setCategoryId(id);
      setLoading(false);
    }
    getCategory();
  }, [params.id]);

  return (
    <Container fluid>
      <Helmet>
        <title>The Yale Politic - {categoryName ? categoryName : "Category"}</title>
      </Helmet>

      <Container fluid>
        <Container fluid className="my-5">
          <Col className="text-center mb-5">
            { loading ? (
              <>
                <span className="text-uppercase">Category</span>
                <h1 className="fs-1">Loading...</h1>
              </>
            ) : ( categoryName === null ? null : (
              <>
                <span className="text-uppercase">Category</span>
                <h1 className="fs-1">{categoryName || "Category"}</h1>
              </>
            ))}
          </Col>
        </Container>
        <Container fluid className="my-5">
          <ArticlesList fetchParams={{categories: categoryId}} />
        </Container>
      </Container>

      <Row className="my-5 adsense-container">
        <Col>
          <AdSense.Google client="ca-pub-6983293802174566" slot="2769088860" />
        </Col>
      </Row>
    </Container>
  )
};

export default Category;
