import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdSense from "react-adsense";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Helmet } from "react-helmet-async";
import { EmailShareButton, FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, EmailIcon } from "react-share";
import { Container, Col, Row } from "react-bootstrap";

import { fetchArticle, fetchUser } from "../utils/api";
import { parseUTC } from "../utils/helper"

const Article = () => {
  const [article, setArticle] = useState(null);
  const [author, setAuthor] = useState(null);
  const [id, setId] = useState(null);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    const getCover = async () => {
      let id = params.article;
      // id = id.slice(10);
      setId(id);
      try {
        let article = await fetchArticle(id);
        let author = await fetchUser(article.author.id);
        setAuthor(author);
        setArticle(article);
      } catch (error) {
        //console.log(error);
      }
    };

    getCover();
  }, [params.article]);

  return (
    <>
      {article === null ? (
        <SkeletonTheme color="#E5E5E5" highlightColor="#F2F2F2">
          <div style={{ margin: "50px 15%" }}>
            <Skeleton variant="rect" height={80} />
            <Skeleton variant="rect" height={400} />
            <Skeleton variant="rect" height={10} />
            <Skeleton variant="rect" height={10} />
            <Skeleton variant="rect" height={10} />
            <Skeleton variant="rect" height={10} />
            <Skeleton variant="rect" height={10} />
            <Skeleton variant="rect" height={10} />
            <Skeleton variant="rect" height={10} />
            <Skeleton variant="rect" height={10} />
          </div>
        </SkeletonTheme>
      ) : (
        <>
          <Helmet>
            <title>
              The Yale Politic -
              {article?.title.rendered ? article?.title.rendered : "Article"}
            </title>
            <meta property="og:title" content={article?.title.rendered} />
            <meta property="og:description" content="" />
            <meta
              property="og:image"
              content={article.imgPost}
            />
          </Helmet>
          <Container fluid className="px-3 mt-2 mb-5" style={{ maxWidth: "900px" }}>
            <Row className="my-5 mx-auto">
              <h1 style={{ fontWeight: "bold", fontSize: "2rem" }} dangerouslySetInnerHTML={{ __html: article.title.rendered }}/>
              <div className="d-flex flex-row gap-3">
                <span>By <Link to={"/author/:id=" + author.id} className="text-black text-decoration-none">{author.name}</Link></span>
                <span>{parseUTC(article.date)}</span>
              </div>
            </Row>
            <Row className="m-0 mx-auto">
              <figure>
                <img alt="" src={article.imgPost} width="100%" height="100%" style={{objectFit: "cover"}} />
                <figcaption dangerouslySetInnerHTML={{ __html: article.caption }}></figcaption>
              </figure>
            </Row>
            <Row className="mt-5 mx-auto">
              <div
                style={{
                  // fontFamily: "Georgia",
                  fontSize: "20px",
                  lineHeight: "1.5",
                  maxWidth: "900px",
                  display: "flex",
                  flexWrap: "wrap",
                  overflow: "hidden",
                }}
                dangerouslySetInnerHTML={{ __html: article.content.rendered }}
              />
              <br />
              <br />
              <div className="adsense-container">
                <AdSense.Google
                  client="ca-pub-6983293802174566"
                  slot="2769088860"
                />
              </div>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default Article;
